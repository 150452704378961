var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "IntelligentSecurity", attrs: { id: "app" } },
    [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "fullScreen" }, [
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.fullScreen,
                expression: "!fullScreen",
              },
            ],
            staticClass: "icon-quanping",
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.fullScreenClick },
          }),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.fullScreen,
                expression: "fullScreen",
              },
            ],
            staticClass: "icon-tuichuquanping",
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.outFullScreenClick },
          }),
        ]),
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "dateTime" }, [
            _c("span", [_vm._v(_vm._s(_vm.currentTime))]),
            _c("span", [_vm._v(_vm._s(_vm.currentDate))]),
          ]),
          _c("div", { staticClass: "statistics" }, [
            _vm._m(0),
            _c("div", [
              _c("span", [_vm._v("在建项目")]),
              _c("span", { staticStyle: { color: "#FFB026" } }, [
                _vm._v(
                  _vm._s(
                    _vm.formaterNumber(_vm.headerData.institutionsAmount)
                  ) + " "
                ),
                _c("span", [_vm._v("个")]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "statistics" }, [
            _vm._m(1),
            _c("div", [
              _c("span", [_vm._v("在建项目人数")]),
              _c("span", { staticStyle: { color: "#26D8FF" } }, [
                _vm._v(
                  _vm._s(
                    _vm.formaterNumber(
                      _vm.headerData.institutionsPersonnelAmount
                    )
                  ) + "\n                        "
                ),
                _c("span", [_vm._v("人")]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "statistics" }, [
            _vm._m(2),
            _c("div", [
              _c("span", [_vm._v("专职安全环保人员")]),
              _c("span", { staticStyle: { color: "#27FFCF" } }, [
                _vm._v(
                  _vm._s(
                    _vm.formaterNumber(_vm.headerData.protectionPersonnel)
                  ) + "\n                        "
                ),
                _c("span", [_vm._v("人")]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "statistics" }, [
            _vm._m(3),
            _c("div", [
              _c("span", [_vm._v("境外风险项目数占比")]),
              _c("span", { staticStyle: { color: "#0BED8B" } }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm.formaterNumber(_vm.headerData.protectionInvolvement)
                    )
                ),
                _c("span", [_vm._v("%")]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "statistics" }, [
            _vm._m(4),
            _c("div", [
              _c("span", [_vm._v("境外风险项目人数占比")]),
              _c("span", { staticStyle: { color: "#1FEBDF" } }, [
                _vm._v(
                  _vm._s(_vm.formaterNumber(_vm.headerData.safeHours)) + " "
                ),
                _c("span", [_vm._v("%")]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "switchProject" },
            [
              _c("avue-input-tree", {
                attrs: {
                  "default-expand-all": "",
                  placeholder: "请选择所属部门",
                  clearable: false,
                  props: _vm.inputTreeProps,
                  dic: _vm.nowUserDeptList,
                },
                on: { change: _vm.chooseDept },
                model: {
                  value: _vm.deptId,
                  callback: function ($$v) {
                    _vm.deptId = $$v
                  },
                  expression: "deptId",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "body" }, [
          _c("div", [
            _c("div", { staticClass: "echartsModelS" }, [
              _c("div", { staticClass: "echartsModelS-title" }, [
                _vm._v("在建项目、运营机构概况"),
              ]),
              _c("div", { staticClass: "echartsModelS-chart listBoxNew" }, [
                _c("div", [
                  _c("span", { staticClass: "listBox-title" }, [
                    _vm._v("境内项目"),
                  ]),
                  _c("div", { staticClass: "itemS" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/visualization/IntelligentSecurity/projectIcon.png"),
                        alt: "",
                      },
                    }),
                    _c("span", [_vm._v("项目数")]),
                    _c("span", { staticStyle: { color: "#ece427" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(
                            _vm.formaterNumber(
                              _vm.InstitutionalOverview.domesticPojAmount
                            )
                          ) +
                          "\n                                "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemS" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/visualization/IntelligentSecurity/personIcon.png"),
                        alt: "",
                      },
                    }),
                    _c("span", [_vm._v("人员数")]),
                    _c("span", { staticStyle: { color: "#00aaff" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(
                            _vm.formaterNumber(
                              _vm.InstitutionalOverview.domesticPersonnelAmount
                            )
                          ) +
                          "\n                                "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "listBox-title" }, [
                    _vm._v("工厂"),
                  ]),
                  _c("div", { staticClass: "itemS" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/visualization/IntelligentSecurity/functionIcon.png"),
                        alt: "",
                      },
                    }),
                    _c("span", [_vm._v("工厂数")]),
                    _c("span", { staticStyle: { color: "#00aaff" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(
                            _vm.formaterNumber(
                              _vm.InstitutionalOverview.factoryAmount
                            )
                          ) +
                          "\n                                "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemS" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/visualization/IntelligentSecurity/personIcon.png"),
                        alt: "",
                      },
                    }),
                    _c("span", [_vm._v("人员数")]),
                    _c("span", { staticStyle: { color: "#00aaff" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(
                            _vm.formaterNumber(
                              _vm.InstitutionalOverview.factoryPersonnelAmount
                            )
                          ) +
                          "\n                                "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "listBox-title" }, [
                    _vm._v("境外项目-机构"),
                  ]),
                  _c("div", { staticClass: "itemL" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/visualization/IntelligentSecurity/projectIcon2.png"),
                        alt: "",
                      },
                    }),
                    _c("span", [_vm._v("项目/机构数")]),
                    _c("span", { staticStyle: { color: "#ece427" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(
                            _vm.formaterNumber(
                              _vm.InstitutionalOverview.abroadPojAmount
                            )
                          ) +
                          "\n                                "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemL" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/visualization/IntelligentSecurity/personIcon.png"),
                        alt: "",
                      },
                    }),
                    _c("span", [_vm._v("人员数")]),
                    _c("span", { staticStyle: { color: "#00aaff" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(
                            _vm.formaterNumber(
                              _vm.InstitutionalOverview.abroadpersonnelAmount
                            )
                          ) +
                          "\n                                "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "echartsModelS" }, [
              _c("div", { staticClass: "echartsModelS-title" }, [
                _vm._v("班前会"),
              ]),
              _c(
                "div",
                { staticClass: "echartsModelS-chart" },
                [
                  _c(
                    "el-carousel",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.BQJDHList.length > "0",
                          expression: "BQJDHList.length > '0'",
                        },
                      ],
                      attrs: {
                        height: "100%",
                        interval: "5000",
                        "indicator-position": "none",
                        arrow: "never",
                      },
                    },
                    _vm._l(_vm.BQJDHList, function (item, index) {
                      return _c(
                        "el-carousel-item",
                        { key: index },
                        _vm._l(item.data, function (items, indexs) {
                          return _c(
                            "div",
                            { key: indexs, staticClass: "elItem" },
                            [
                              _c("div", { staticClass: "elItemImageBox" }, [
                                _c("img", {
                                  staticClass: "elItemImage",
                                  attrs: { src: items.imgSrc, alt: "" },
                                }),
                              ]),
                              _c("div", { staticClass: "LBtxt" }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "LBtitle",
                                      staticStyle: {
                                        color: "#00f0ff",
                                        "font-weight": "500",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(items.title) +
                                          "\n                                            "
                                      ),
                                    ]
                                  ),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dian2 LBcontet",
                                      staticStyle: {
                                        color: "#fff",
                                        "font-weight": "500",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                                " +
                                          _vm._s(items.meetingContents) +
                                          "\n                                            "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "LBtime",
                                    staticStyle: {
                                      color: "#fff",
                                      "font-weight": "500",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            " +
                                        _vm._s(items.meetingTime) +
                                        "\n                                        "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.BQJDHList.length == "0",
                          expression: "BQJDHList.length == '0'",
                        },
                      ],
                      staticClass: "Nodataavailableatthemoment",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        width: "100%",
                        "align-items": "center",
                        height: "100%",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "27%" },
                        attrs: { src: _vm.emptyUrl, alt: "" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._m(5),
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "earthMap" },
              [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeWordMap,
                      expression: "activeWordMap",
                    },
                  ],
                  staticStyle: { position: "relative", "z-index": "888" },
                  attrs: { id: "earthMap" },
                }),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.activeWordMap,
                      expression: "!activeWordMap",
                    },
                  ],
                  staticStyle: { position: "relative", "z-index": "888" },
                  attrs: { id: "wordMap" },
                }),
                _c("div", { staticClass: "wordMapBox" }, [
                  _c(
                    "div",
                    {
                      class: {
                        activeWordMap: _vm.activeWordMap,
                        wordMap: !_vm.activeWordMap,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkMapState(true)
                        },
                      },
                    },
                    [_vm._v("3D地图")]
                  ),
                  _c(
                    "div",
                    {
                      class: {
                        activeWordMap: !_vm.activeWordMap,
                        wordMap: _vm.activeWordMap,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.checkMapState(false)
                        },
                      },
                    },
                    [_vm._v("世界地图")]
                  ),
                ]),
                _c("loading", {
                  attrs: {
                    text: "正在加载地图",
                    loadingState: _vm.loadingState,
                  },
                }),
                _vm._m(6),
                _vm._m(7),
              ],
              1
            ),
          ]),
          _c("div", [
            _c("div", { staticClass: "echartsModelS" }, [
              _c("div", { staticClass: "echartsModelS-title" }, [
                _vm._v("安全培训覆盖率"),
              ]),
              _c("div", { staticClass: "echartsModelS-chart circleChart" }, [
                _c("div", { attrs: { id: "echartsRT" } }),
                _c("div", [
                  _c("div", [_vm._v("培训次数")]),
                  _c("div", [_vm._v("参与培训人次")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.formaterNumber(_vm.safetyRate.trainingAmount))
                    ),
                  ]),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.formaterNumber(_vm.safetyRate.trainingPersonnel)
                      )
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._m(8),
            _vm._m(9),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../assets/visualization/IntelligentSecurity/headerIcon1.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../assets/visualization/IntelligentSecurity/headerIcon2.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../assets/visualization/IntelligentSecurity/headerIcon3.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../assets/visualization/IntelligentSecurity/headerIcon4.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../assets/visualization/IntelligentSecurity/headerIcon5.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "echartsModelS" }, [
      _c("div", { staticClass: "echartsModelS-title" }, [
        _vm._v("项目整改统计图"),
      ]),
      _c("div", {
        staticClass: "echartsModelS-chart",
        attrs: { id: "echartsLB" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "b1" }, [
      _c("div", { staticClass: "xg0 xg" }),
      _c("div", { staticClass: "xg1 xg" }),
      _c("div", { staticClass: "xg2 xg" }),
      _c("div", { staticClass: "xg3 xg" }),
      _c("div", { staticClass: "xg4 xg" }),
      _c("div", { staticClass: "xg5 xg" }),
      _c("div", { staticClass: "xg6 xg" }),
      _c("div", { staticClass: "xg61 xg" }),
      _c("div", { staticClass: "xg7 xg" }),
      _c("div", { staticClass: "xg2c xg" }),
      _c("div", { staticClass: "xg2c1 xg" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rainBox" }, [
      _c("canvas", {
        staticClass: "rain",
        attrs: { width: "1186", height: "731" },
      }),
      _c("canvas", {
        staticClass: "rain2",
        attrs: { width: "1186", height: "731" },
      }),
      _c("canvas", {
        staticClass: "rain3",
        attrs: { width: "1186", height: "731" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "echartsModelS" }, [
      _c("div", { staticClass: "echartsModelS-title" }, [
        _vm._v("培训次数统计"),
      ]),
      _c("div", {
        staticClass: "echartsModelS-chart",
        attrs: { id: "echartsRC" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "echartsModelS" }, [
      _c("div", { staticClass: "echartsModelS-title" }, [
        _vm._v("年度安全检查统计"),
      ]),
      _c("div", {
        staticClass: "echartsModelS-chart",
        attrs: { id: "echartsRB" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }